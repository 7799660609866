import { environment } from "../utils/environment";

export const config = {
  environment: {
    get authenticationUrl(): string {
      return process.env[
        `REACT_APP_${environment()}_AUTHENTICATION_URL`
      ] as string;
    },
    get gatewayUrl(): string {
      return process.env[`REACT_APP_${environment()}_GATEWAY_URL`] as string;
    },
    get cmeApiUrl(): string {
      return process.env[`REACT_APP_${environment()}_CME_API_URL`] as string;
    },
    // This is used as a short cut to trigger the LoginPage's cme'ness, will be refactored away
    get cmeHandoffUrl(): string {
      return process.env[
        `REACT_APP_${environment()}_CME_HANDOFF_URL`
      ] as string;
    },
    get profileUrl(): string {
      return process.env[`REACT_APP_${environment()}_PROFILE_URL`] as string;
    },
    get isEmailPreferenceEnabled(): boolean {
      return JSON.parse(
        process.env[`REACT_APP_${environment()}_EMAIL_PREFERENCE`] ?? "false"
      );
    },
    get isGraduationYearEnabled(): boolean {
      return JSON.parse(
        process.env[`REACT_APP_${environment()}_GRADUATION_YEAR`] ?? "false"
      );
    },
    get entitlementUrl(): string {
      return process.env[
        `REACT_APP_${environment()}_ENTITLEMENT_URL`
      ] as string;
    },
    get purchaseUrl(): string {
      return process.env[`REACT_APP_${environment()}_PURCHASE_URL`] as string;
    },
    get amplitude_project_name(): string {
      return process.env[`REACT_APP_${environment()}_PROJECT_NAME`] as string;
    },
    get segmentApiKey(): string {
      return (
        process.env[`REACT_APP_${environment()}_SEGMENT_ANALYTICS_WRITE_KEY`] ??
        ""
      );
    },
    get reCAPTCHA_site_key(): string {
      return process.env[
        `REACT_APP_${environment()}_RECAPTCHA_SITE_KEY`
      ] as string;
    },
    get integrationTest(): boolean {
      return JSON.parse(
        process.env[`REACT_APP_${environment()}_INTEGRATION_TEST`] || "false"
      );
    },
    get legacyLoginToggle(): boolean {
      return JSON.parse(
        process.env[`REACT_APP_${environment()}_LEGACY_LOGIN`] || "false"
      );
    },
    get baseEpocratesUrl(): string {
      return process.env[
        `REACT_APP_${environment()}_BASE_EPOCRATES_URL`
      ] as string;
    },
    get npiUrl(): string {
      return process.env[`REACT_APP_${environment()}_NPI_URL`] as string;
    },
    get recurlyPublicKey(): string {
      return process.env[
        `REACT_APP_${environment()}_RECURLY_PUBLIC_KEY`
      ] as string;
    },
    get supportUrl(): string {
      return process.env[`REACT_APP_${environment()}_SUPPORT_URL`] as string;
    },
    get vindiciaAuthId(): string {
      return process.env[
        `REACT_APP_${environment()}_VINDICIA_AUTH_ID`
      ] as string;
    },
    get vindiciaHmac(): string {
      return process.env[`REACT_APP_${environment()}_VINDICIA_HMAC`] as string;
    },
    get vindiciaServer(): string {
      return process.env[
        `REACT_APP_${environment()}_VINDICIA_SERVER`
      ] as string;
    },
    get vindiciaRestServer(): string {
      return process.env[
        `REACT_APP_${environment()}_VINDICIA_REST_SERVER`
      ] as string;
    },
    get eolURL(): string {
      return process.env[`REACT_APP_${environment()}_EOL_URL`] as string;
    },
    get isCancelFlowEnabled(): string {
      return (
        process.env[`REACT_APP_${environment()}_CANCEL_FLOW_ENABLED`] ?? "false"
      );
    },
    get optimizelyKey(): string {
      return (
        process.env[`REACT_APP_${environment()}_OPTIMIZELY_SDK_KEY`] ?? "false"
      );
    },
    get recurlyRedemption(): string {
      return process.env[
        `REACT_APP_${environment()}_RECURLY_REDEMPTION`
      ] as string;
    },
    get recurly(): string {
      return process.env[`REACT_APP_${environment()}_RECURLY`] as string;
    },
  },
};
