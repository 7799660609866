import React, { useEffect, useState, MouseEvent } from "react";
import {
  FormControl,
  makeStyles,
  Theme,
  TextField as MaterialTextField,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core";
import clsx from "clsx";
import { colors } from "../../config/colorConfig";
import lockIcon from "../../images/lockIcon.svg";

interface State {
  validationError: string;
  validationInformation: string;
  isError: boolean;
  value: any;
}

const TextFieldBeforeMemo = (props: any) => {
  const [useGreySemi, setUseGreySemi] = useState<boolean>(
    props.greyInputUntilActive ?? false
  );
  const textFieldStyle = makeStyles((theme: Theme) => ({
    textField: {
      width: props.width || "100%",
      backgroundColor: colors["--white"],
    },

    label: {
      color: colors["--ui-grey-semi"],
    },
    input: {
      color: colors["--ui-slate"],
    },
  }));
  const classes = textFieldStyle();

  const [values, setValues] = React.useState<State>({
    validationError: "",
    validationInformation: "",
    isError: false,
    value: props.value,
  });

  const validateField = (event: any) => {
    const { validation } = props;
    if (props.validation) {
      // Passing the event type back to caller fn and it is optinal
      const validMessage = validation(event.target.value, event.type);
      if (validMessage) {
        setValues({
          ...values,
          validationError: validMessage,
          isError: true,
          value: event.target.value,
        });
      } else {
        setValues({
          ...values,
          validationError: "",
          isError: false,
          value: event.target.value,
        });
      }
    } else {
      setValues({
        ...values,
        validationError: "",
        isError: false,
        value: event.target.value,
      });
    }

    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  useEffect(() => {
    const event = { target: { value: props.value ?? "" } };
    validateField(event);
  }, props.revalidateOnChanged || []);

  useEffect(() => {
    if (props.value !== undefined || props.value !== null) {
      setValues({ ...values, value: props.value });
    }
  }, [props.value]);

  useEffect(() => {
    if (props.validationInformation) {
      setValues({
        ...values,
        validationInformation: props.validationInformation,
      });
    } else {
      setValues({
        ...values,
        validationInformation: "",
      });
    }
    if (props.validationError) {
      setValues({
        ...values,
        validationError: props.validationError,
        isError: true,
        value: props.value,
      });
    } else {
      setValues({
        ...values,
        validationError: "",
        isError: false,
        value: props.value,
      });
    }
  }, [props.validationError, props.validationInformation]);

  function onClickWrapper(event: MouseEvent<HTMLInputElement>): void {
    setUseGreySemi(false);
    props.onClickField && props.onClickField(event);
  }

  function RenderEndAdornment() {
    const endButton = (
      <InputAdornment position="end">{props.endButton}</InputAdornment>
    );
    return props.disabled ? (
      <InputAdornment position="end">
        <img src={lockIcon} alt="" />
      </InputAdornment>
    ) : props.endButton ? (
      endButton
    ) : undefined;
  }

  return (
    <FormControl required fullWidth>
      <MaterialTextField
        required={props.isRequired !== "false"}
        error={values.isError}
        autoFocus={props.autoFocus}
        id={props.name}
        name={props.name}
        type={props.type}
        placeholder={props.label}
        data-testid={props.testId}
        label={props.isNewDesign ? "" : props.label}
        autoComplete={props.noAutoComplete ? "off" : "on"}
        inputProps={{
          classes: { input: classes.input },
          maxLength: 100,
          id: `${props.testId}_input`,
          autoCapitalize: props.autoCapitalize,
          style: useGreySemi ? { color: colors["--ui-grey-semi"] } : undefined,
          "aria-label": props.label,
        }}
        InputLabelProps={{
          className: classes.label,
          shrink: props.shrink,
        }}
        className={clsx(classes.textField)}
        onClick={onClickWrapper}
        onBlur={validateField}
        onChange={validateField}
        disabled={props.disabled}
        variant="outlined"
        value={values.value}
        onPaste={
          props.disablePaste ? (event) => event.preventDefault() : undefined
        }
        inputRef={props.inputRef}
        InputProps={{
          endAdornment: RenderEndAdornment(),
        }}
      />
      {values.validationError ? (
        <FormHelperText
          data-testid={props.helpTestId}
          style={{ color: colors["--failure"], paddingLeft: "14px" }}
        >
          {values.validationError || " "}
        </FormHelperText>
      ) : (
        <FormHelperText
          data-testid={props.helpTestId}
          style={{ color: colors["--ui-slate"], paddingLeft: "14px" }}
        >
          {values.validationInformation || (props.endButton ? "" : " ")}
        </FormHelperText>
      )}
    </FormControl>
  );
};
export const TextField = React.memo(TextFieldBeforeMemo);
