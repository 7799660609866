/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createTheme, styled, ThemeProvider } from "@mui/system";
import {
  CardCvvElement,
  CardMonthElement,
  CardNumberElement,
  CardYearElement,
  Elements,
  RecurlyProvider,
  useRecurly,
} from "@recurly/react-recurly";
import React, { ChangeEvent } from "react";
import { PaymentIcon, PaymentType } from "react-svg-credit-card-payment-icons";
import { colors } from "src/config/colorConfig";
import { config } from "src/config/envConfig";
import { BillingAddress } from "src/models/BillingAddress";
import { RecurlyCreditCardToken } from "src/models/RecurlyToken";

interface RecurlyPaymentAndBillingProps {
  recurlyTokenHandler: (token: RecurlyCreditCardToken) => void;
}

interface ErrorType {
  field: string;
  message: string;
}

interface ThemedInputComponentProps {
  variant?: "error";
}

const customTheme = createTheme({
  components: {
    FullWidthTextInput: {
      variants: [
        {
          props: { variant: "error" },
          style: {
            border: `1px solid ${colors["--error"]}`,
          },
        },
      ],
    },
    HalfWidthTextInput: {
      variants: [
        {
          props: { variant: "error" },
          style: {
            border: `1px solid ${colors["--error"]}`,
          },
        },
      ],
    },
  },
});

const AdditionalRow = styled("tr")({
  borderTop: `2px solid ${colors["--ui-grey-light"]}`,
});
const ColumnSection = styled("div")({
  display: "flex",
});
const ColumnSectionWithFloatingSecondColumn = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));
const ConfirmationSection = styled("div")(({ theme }) => ({
  color: colors["--primary-midnight"],
  lineHeight: "24px",
  width: theme.spacing(89),
}));
const ConfirmationTable = styled("table")({
  border: `2px solid ${colors["--ui-grey-light"]}`,
  borderRadius: "3px",
  width: "100%",
});
const ContinueButton = styled("button")({
  backgroundColor: colors["--primary-tealnight"],
  border: 0,
  borderRadius: "3px",
  color: "white",
  fontWeight: "600",
  fontSize: "18px",
  height: "48px",
  lineHeight: "24px",
  width: "264px",
});
const EditLink = styled("span")({
  color: colors["--primary-tealnight"],
  fontSize: "17px",
  textDecoration: "underline",
});
const ErrorMessage = styled("div")({
  color: colors["--error"],
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
});
const FullComponent = styled("div")(({ theme }) => ({
  fontFamily: "Source Sans Pro",
  margin: theme.spacing(3),
}));
const FullWidthTextInput = styled("input", {
  name: "FullWidthTextInput",
  shouldForwardProp: (prop) => prop !== "variant",
})<ThemedInputComponentProps>(({ theme }) => ({
  backgroundColor: colors["--white"],
  border: `1px solid ${colors["--ui-grey-med"]}`,
  borderRadius: "3px",
  boxSizing: "border-box",
  height: "2em",
  marginTop: "10px",
  padding: "1px 10px",
  width: "100%",
  "&::placeholder": {
    color: colors["--ui-gray"],
  },
}));
const HalfWidthFirstColumn = styled("div")(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "400",
  width: theme.spacing(30),
}));
const HalfWidthSecondColumn = styled("div")(({ theme }) => ({
  float: "right",
  fontSize: "16px",
  fontWeight: "400",
  width: theme.spacing(30),
}));
const HalfWidthTextInput = styled("input", {
  name: "HalfWidthTextInput",
  shouldForwardProp: (prop) => prop !== "variant",
})<ThemedInputComponentProps>(({ theme }) => ({
  backgroundColor: colors["--white"],
  border: `1px solid ${colors["--ui-grey-med"]}`,
  borderRadius: "3px",
  boxSizing: "border-box",
  height: "2em",
  marginTop: "10px",
  padding: "1px 10px",
  width: theme.spacing(30),
  "&::placeholder": {
    color: colors["--ui-gray"],
  },
}));
const Label = styled("div")({
  fontWeight: "600",
});
const LabelInputSection = styled("div")(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "400",
  marginBottom: theme.spacing(3),
}));
const LeftColumn = styled("td")(({ theme }) => ({
  fontWeight: "600",
  padding: theme.spacing(3),
  width: "10%",
}));
const MajorFormSection = styled("div")(({ theme }) => ({
  color: colors["--primary-midnight"],
  lineHeight: "24px",
  width: theme.spacing(61),
}));
const MiddleColumn = styled("td")(({ theme }) => ({
  paddingRight: theme.spacing(3),
  width: "80%",
}));
const Required = styled("span")({
  color: colors["--failure"],
});
const RightColumn = styled("td")(({ theme }) => ({
  fontWeight: "600",
  padding: theme.spacing(3),
  width: "10%",
}));
const SectionHeader = styled("div")(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "700",
  marginBottom: theme.spacing(1),
}));
const StyledCardMonthElement = styled(CardMonthElement)(({ theme }) => ({
  paddingRight: theme.spacing(1),
}));
const TableContent = styled("span")(({ theme }) => ({
  padding: theme.spacing(1),
  verticalAlign: "middle",
}));

const RecurlyPaymentAndBillingForm = (props: RecurlyPaymentAndBillingProps) => {
  const FIRST_NAME_REQUIRED_ERROR = "First name can't be blank";
  const LAST_NAME_REQUIRED_ERROR = "Last name can't be blank";
  const ADDRESS_REQUIRED_ERROR = "Address can't be blank";
  const CITY_REQUIRED_ERROR = "City can't be blank";
  const STATE_REQUIRED_ERROR = "State can't be blank";
  const ZIP_CODE_REQUIRED_ERROR = "Zip code can't be blank";
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [addressLine1, setAddressLine1] = React.useState("");
  const [addressLine2, setAddressLine2] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [token, setToken] = React.useState<RecurlyCreditCardToken>();
  const [billingAddress, setBillingAddress] = React.useState<BillingAddress>();
  const formRef = React.useRef();
  const recurly = useRecurly();
  const [errors, setErrors] = React.useState<ErrorType[]>([]);
  const [cardNumberElementLengthWas, setCardNumberElementLengthWas] =
    React.useState(0);
  const [cardMonthElementLengthWas, setCardMonthElementLengthWas] =
    React.useState(0);
  const [cardYearElementLengthWas, setCardYearElementLengthWas] =
    React.useState(0);

  function setError(field: string, message: string) {
    setErrors((prev) => [
      ...prev.filter((e) => e.field !== field),
      { field, message },
    ]);
  }

  function clearError(field: string) {
    setErrors((prev) => prev.filter((e) => e.field !== field));
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (formRef.current) {
      validateNonRecurlyFormElements();
      if (formIsValid()) {
        recurly.token(formRef.current, (err, token) => {
          if (!err) {
            const creditCardToken: RecurlyCreditCardToken =
              token as RecurlyCreditCardToken;
            props.recurlyTokenHandler(creditCardToken);
            setBillingAddress(buildBillingAddressFromForm());
            setToken(creditCardToken);
            setShowConfirmation(true);
          } else {
            setRecurlyErrors(err);
          }
        });
      }
    }
  };

  function validateNonRecurlyFormElements() {
    if (firstName === "") {
      setError("firstName", FIRST_NAME_REQUIRED_ERROR);
    } else {
      clearError("firstName");
    }
    if (lastName === "") {
      setError("lastName", LAST_NAME_REQUIRED_ERROR);
    } else {
      clearError("lastName");
    }
    if (addressLine1 === "") {
      setError("addressLine1", ADDRESS_REQUIRED_ERROR);
    } else {
      clearError("addressLine1");
    }
    if (city === "") {
      setError("city", CITY_REQUIRED_ERROR);
    } else {
      clearError("city");
    }
    if (state === "") {
      setError("state", STATE_REQUIRED_ERROR);
    } else {
      clearError("state");
    }
    if (zipCode === "") {
      setError("zipCode", ZIP_CODE_REQUIRED_ERROR);
    } else {
      clearError("zipCode");
    }
  }

  function formIsValid(): boolean {
    return errors.length === 0;
  }

  function setRecurlyErrors(err: any) {
    if (err.details) {
      clearError("number");
      clearError("expDate");
      clearError("cvv");
      err.details.forEach((error: { field: string; messages: string[] }) => {
        switch (error.field) {
          case "number":
            error.messages.forEach((message: string) => {
              setError("number", `Credit Card number ${message}`);
            });
            break;
          case "month":
            error.messages.forEach((message: string) => {
              setError("expDate", `Expiration date ${message}`);
            });
            break;
          case "year":
            error.messages.forEach((message: string) => {
              setError("expDate", `Expiration date ${message}`);
            });
            break;
          case "cvv":
            error.messages.forEach((message: string) => {
              setError("cvv", `CVV ${message}`);
            });
            break;
          default:
            break;
        }
      });
    }
  }

  const cardNumberElement: any = React.useRef(null);
  const cardMonthElement: any = React.useRef(null);
  const cardYearElement: any = React.useRef(null);
  const cardCVVElement: any = React.useRef(null);

  function buildBillingAddressFromForm() {
    const billingAddress: BillingAddress = {
      address1: addressLine1,
      address2: addressLine2,
      city: city,
      state: state,
      zip: zipCode,
    };
    return billingAddress;
  }

  function handleNonCCInputChange(event: ChangeEvent<HTMLInputElement>) {
    const targetName = event.target.name;
    const targetValue = event.target.value;
    switch (targetName) {
      case "credit-card-first-name":
        setFirstName(targetValue);
        if (targetValue === "") {
          setError("firstName", FIRST_NAME_REQUIRED_ERROR);
        } else {
          clearError("firstName");
        }
        break;
      case "credit-card-last-name":
        setLastName(targetValue);
        if (targetValue === "") {
          setError("lastName", LAST_NAME_REQUIRED_ERROR);
        } else {
          clearError("lastName");
        }
        break;
      case "billing-address-line-1":
        setAddressLine1(targetValue);
        if (targetValue === "") {
          setError("addressLine1", ADDRESS_REQUIRED_ERROR);
        } else {
          clearError("addressLine1");
        }
        break;
      case "billing-address-line-2":
        setAddressLine2(targetValue);
        break;
      case "billing-address-city":
        setCity(targetValue);
        if (targetValue === "") {
          setError("city", CITY_REQUIRED_ERROR);
        } else {
          clearError("city");
        }
        break;
      case "billing-address-state":
        setState(targetValue);
        if (targetValue === "") {
          setError("state", STATE_REQUIRED_ERROR);
        } else {
          clearError("state");
        }
        break;
      case "billing-address-zip-code":
        setZipCode(targetValue);
        if (targetValue === "") {
          setError("zipCode", ZIP_CODE_REQUIRED_ERROR);
        } else {
          clearError("zipCode");
        }
        break;
      default:
        break;
    }
  }

  function handleCardNumberChange(args: { brand: string; length: number }) {
    clearError("number");
    if (
      args.brand === "american_express" &&
      args.length === 15 &&
      cardNumberElementLengthWas !== 15
    ) {
      cardMonthElement.current._element.focus();
    } else if (
      args.brand !== "american_express" &&
      args.length === 16 &&
      cardNumberElementLengthWas !== 16
    ) {
      cardMonthElement.current._element.focus();
    }
    setCardNumberElementLengthWas(args.length);
  }

  function handleCardMonthChange(args: { length: number }) {
    clearError("expDate");
    if (args.length === 2 && cardMonthElementLengthWas !== 2) {
      cardYearElement.current._element.focus();
    }
    setCardMonthElementLengthWas(args.length);
  }

  function handleCardYearChange(args: { length: number }) {
    clearError("expDate");
    if (args.length === 2 && cardYearElementLengthWas !== 2) {
      cardCVVElement.current._element.focus();
    }
    setCardYearElementLengthWas(args.length);
  }

  function handleCardCVVChange() {
    clearError("cvv");
  }

  function getCCBrandIcon() {
    if (token?.card.brand) {
      let type: PaymentType = "Generic";
      switch (token.card.brand) {
        case "visa":
          type = "Visa";
          break;
        case "master":
          type = "Mastercard";
          break;
        case "american_express":
          type = "Amex";
          break;
        case "discover":
          type = "Discover";
          break;
        case "jcb":
          type = "Jcb";
          break;
        case "maestro":
          type = "Maestro";
          break;
        case "elo":
          type = "Elo";
          break;
        case "hipercard":
          type = "Hipercard";
          break;
        case "union_pay":
          type = "Unionpay";
          break;
        default:
          type = "Generic";
          break;
      }
      return (
        <PaymentIcon
          type={type}
          format={type === "Generic" ? "mono" : "flatRounded"}
          width={50}
          enableBackground={"white"}
        />
      );
    }
  }

  function editForm() {
    setShowConfirmation(false);
  }

  return (
    <ThemeProvider theme={customTheme}>
      <FullComponent>
        <div style={{ display: showConfirmation ? "none" : "block" }}>
          <form
            // @ts-ignore
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <MajorFormSection id="payment-details-section">
              <SectionHeader>Enter payment information</SectionHeader>
              <LabelInputSection>
                <Label>
                  <Required>* </Required>
                  Name on card
                </Label>
                <ColumnSectionWithFloatingSecondColumn>
                  <HalfWidthFirstColumn>
                    <FullWidthTextInput
                      data-recurly="first_name"
                      id="credit-card-first-name"
                      maxLength={50}
                      name="credit-card-first-name"
                      onChange={handleNonCCInputChange}
                      placeholder="First Name"
                      type="text"
                      value={firstName}
                      variant={
                        errors.find((e) => e.field === "firstName")
                          ? "error"
                          : undefined
                      }
                    />
                    {errors.find((e) => e.field === "firstName") && (
                      <ErrorMessage>
                        {errors.find((e) => e.field === "firstName")?.message}
                      </ErrorMessage>
                    )}
                  </HalfWidthFirstColumn>
                  <HalfWidthSecondColumn>
                    <FullWidthTextInput
                      data-recurly="last_name"
                      id="credit-card-last-name"
                      maxLength={50}
                      name="credit-card-last-name"
                      onChange={handleNonCCInputChange}
                      placeholder="Last Name"
                      type="text"
                      value={lastName}
                      variant={
                        errors.find((e) => e.field === "lastName")
                          ? "error"
                          : undefined
                      }
                    />
                    {errors.find((e) => e.field === "lastName") && (
                      <ErrorMessage>
                        {errors.find((e) => e.field === "lastName")?.message}
                      </ErrorMessage>
                    )}
                  </HalfWidthSecondColumn>
                </ColumnSectionWithFloatingSecondColumn>
              </LabelInputSection>
              <LabelInputSection>
                <Label>
                  <Required>* </Required>
                  Card Number
                </Label>
                <CardNumberElement
                  onChange={handleCardNumberChange}
                  // @ts-ignore
                  ref={cardNumberElement}
                  style={{
                    fontColor: colors["--primary-midnight"],
                    fontFamily: "Source Sans Pro",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    placeholder: {
                      color: colors["--ui-gray"],
                      content: "Card number",
                    },
                  }}
                />
                {errors.find((e) => e.field === "number") && (
                  <ErrorMessage>
                    {errors.find((e) => e.field === "number")?.message}
                  </ErrorMessage>
                )}
              </LabelInputSection>
              <LabelInputSection>
                <ColumnSectionWithFloatingSecondColumn>
                  <HalfWidthFirstColumn>
                    <Label>
                      <Required>* </Required>
                      Expiration date
                    </Label>
                    <ColumnSection>
                      <StyledCardMonthElement
                        onChange={handleCardMonthChange}
                        // @ts-ignore
                        ref={cardMonthElement}
                        style={{
                          fontColor: colors["--primary-midnight"],
                          fontFamily: "Source Sans Pro",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          placeholder: {
                            color: colors["--ui-gray"],
                            content: "MM",
                          },
                        }}
                      />
                      <CardYearElement
                        onChange={handleCardYearChange}
                        // @ts-ignore
                        ref={cardYearElement}
                        style={{
                          fontColor: colors["--primary-midnight"],
                          fontFamily: "Source Sans Pro",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          placeholder: {
                            color: colors["--ui-gray"],
                            content: "YY",
                          },
                        }}
                      />
                    </ColumnSection>
                    {errors.find((e) => e.field === "expDate") && (
                      <ErrorMessage>
                        {errors.find((e) => e.field === "expDate")?.message}
                      </ErrorMessage>
                    )}
                  </HalfWidthFirstColumn>
                  <HalfWidthSecondColumn>
                    <Label>
                      <Required>* </Required>
                      Security code
                    </Label>
                    <div>
                      <CardCvvElement
                        onChange={handleCardCVVChange}
                        // @ts-ignore
                        ref={cardCVVElement}
                        style={{
                          fontColor: colors["--primary-midnight"],
                          fontFamily: "Source Sans Pro",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          placeholder: {
                            color: colors["--ui-gray"],
                            content: "CVV",
                          },
                        }}
                      />
                    </div>
                    {errors.find((e) => e.field === "cvv") && (
                      <ErrorMessage>
                        {errors.find((e) => e.field === "cvv")?.message}
                      </ErrorMessage>
                    )}
                  </HalfWidthSecondColumn>
                </ColumnSectionWithFloatingSecondColumn>
              </LabelInputSection>
            </MajorFormSection>
            <MajorFormSection>
              <SectionHeader>Enter billing address</SectionHeader>
              <LabelInputSection>
                <Label>
                  <Required>* </Required>
                  Address line 1
                </Label>
                <div>
                  <FullWidthTextInput
                    data-recurly="address1"
                    id="billing-address-line-1"
                    name="billing-address-line-1"
                    onChange={handleNonCCInputChange}
                    placeholder="Address line 1"
                    type="text"
                    value={addressLine1}
                    variant={
                      errors.find((e) => e.field === "addressLine1")
                        ? "error"
                        : undefined
                    }
                  />
                </div>
                {errors.find((e) => e.field === "addressLine1") && (
                  <ErrorMessage>
                    {errors.find((e) => e.field === "addressLine1")?.message}
                  </ErrorMessage>
                )}
              </LabelInputSection>
              <LabelInputSection>
                <Label>Address line 2 (optional)</Label>
                <div>
                  <FullWidthTextInput
                    data-recurly="address2"
                    id="billing-address-line-2"
                    name="billing-address-line-2"
                    onChange={handleNonCCInputChange}
                    placeholder="Address line 2 (optional)"
                    type="text"
                    value={addressLine2}
                  />
                </div>
              </LabelInputSection>
              <LabelInputSection>
                <Label>
                  <Required>* </Required>
                  City
                </Label>
                <div>
                  <FullWidthTextInput
                    data-recurly="city"
                    id="billing-address-city"
                    name="billing-address-city"
                    onChange={handleNonCCInputChange}
                    placeholder="City"
                    type="text"
                    value={city}
                    variant={
                      errors.find((e) => e.field === "city")
                        ? "error"
                        : undefined
                    }
                  />
                </div>
                {errors.find((e) => e.field === "city") && (
                  <ErrorMessage>
                    {errors.find((e) => e.field === "city")?.message}
                  </ErrorMessage>
                )}
              </LabelInputSection>
              <LabelInputSection>
                <ColumnSectionWithFloatingSecondColumn>
                  <HalfWidthFirstColumn>
                    <Label>
                      <Required>* </Required>
                      State
                    </Label>
                    <div>
                      <HalfWidthTextInput
                        data-recurly="state"
                        id="billing-address-state"
                        name="billing-address-state"
                        onChange={handleNonCCInputChange}
                        placeholder="State"
                        type="text"
                        value={state}
                        variant={
                          errors.find((e) => e.field === "state")
                            ? "error"
                            : undefined
                        }
                      />
                    </div>
                    {errors.find((e) => e.field === "state") && (
                      <ErrorMessage>
                        {errors.find((e) => e.field === "state")?.message}
                      </ErrorMessage>
                    )}
                  </HalfWidthFirstColumn>
                  <HalfWidthSecondColumn>
                    <Label>
                      <Required>* </Required>
                      Zip code
                    </Label>
                    <div>
                      <HalfWidthTextInput
                        data-recurly="postal_code"
                        id="billing-address-zip-code"
                        name="billing-address-zip-code"
                        onChange={handleNonCCInputChange}
                        placeholder="Zip code"
                        type="text"
                        value={zipCode}
                        variant={
                          errors.find((e) => e.field === "zipCode")
                            ? "error"
                            : undefined
                        }
                      />
                      {errors.find((e) => e.field === "zipCode") && (
                        <ErrorMessage>
                          {errors.find((e) => e.field === "zipCode")?.message}
                        </ErrorMessage>
                      )}
                      <input
                        data-recurly="country"
                        id="billing-address-country"
                        name="billing-address-country"
                        type="text"
                        value="United States"
                        hidden
                      />
                    </div>
                  </HalfWidthSecondColumn>
                </ColumnSectionWithFloatingSecondColumn>
              </LabelInputSection>
            </MajorFormSection>
            <ContinueButton type="submit">Continue</ContinueButton>
          </form>
        </div>
        <div style={{ display: showConfirmation ? "block" : "none" }}>
          <ConfirmationSection>
            <SectionHeader>Confirm payment & billing information</SectionHeader>
            <div>
              <ConfirmationTable>
                <tr>
                  <LeftColumn>Payment method</LeftColumn>
                  <MiddleColumn>
                    <>
                      <TableContent>{getCCBrandIcon()}</TableContent>
                      <TableContent>
                        &#x25CF;&#x25CF;&#x25CF;&#x25CF;
                        &#x25CF;&#x25CF;&#x25CF;&#x25CF;
                        &#x25CF;&#x25CF;&#x25CF;&#x25CF; {token?.card.last_four}
                      </TableContent>
                      <TableContent>
                        {token?.card.exp_month}/{token?.card.exp_year}
                      </TableContent>
                    </>
                  </MiddleColumn>
                  <RightColumn>
                    <EditLink onClick={editForm}>Edit</EditLink>
                  </RightColumn>
                </tr>
                <AdditionalRow>
                  <LeftColumn>Billing address</LeftColumn>
                  <MiddleColumn>
                    <TableContent>
                      {billingAddress?.address1} {billingAddress?.address2}{" "}
                      {billingAddress?.city}, {billingAddress?.state}{" "}
                      {billingAddress?.zip}
                    </TableContent>
                  </MiddleColumn>
                  <RightColumn>
                    <EditLink onClick={editForm}>Edit</EditLink>
                  </RightColumn>
                </AdditionalRow>
              </ConfirmationTable>
            </div>
          </ConfirmationSection>
        </div>
      </FullComponent>
    </ThemeProvider>
  );
};

export const RecurlyPaymentAndBillingComponent = (
  props: RecurlyPaymentAndBillingProps
) => {
  return (
    <>
      <RecurlyProvider publicKey={config.environment.recurlyPublicKey}>
        <Elements>
          <RecurlyPaymentAndBillingForm
            recurlyTokenHandler={props.recurlyTokenHandler}
          />
        </Elements>
      </RecurlyProvider>
    </>
  );
};
